import { createContext, useContext, PropsWithChildren } from 'react';
import { ClientConfig } from '../../types';

export const ConfigContext = createContext<ClientConfig | undefined>(undefined);

export const useConfigContext = (): ClientConfig => {
    const ctx = useContext(ConfigContext);
    if (typeof ctx === 'undefined') {
        throw new Error('useConfigContext must be used within the provider.');
    }
    return ctx;
};

export const ConfigProvider = ({
    config,
    children,
}: PropsWithChildren<{ config: ClientConfig }>): JSX.Element => {
    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};
